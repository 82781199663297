.FormRow {
    margin: 10px 0;
}

.FormRow:first-child {
    margin-top: 0;
}

.FormRow:last-child {
    margin-bottom: 0;
}