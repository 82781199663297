.placement-rack {
    /*bottom: 0;*/
    /*position: absolute;*/
    /*left: 0;*/
    /*right: 222px;*/
    /*overflow: auto;*/
    /*top: 0;*/
    /*z-index: 2;*/
    position: fixed;
    left: 0;
    right: 250px;
    bottom: 0;
    top: 0;
    z-index: 2;
    overflow: auto;
    /*margin-right: 250px;*/
}

.placement-rack.full {
    /*right: 20px;*/
    margin-right: 0;
}

.placement-advertisers {
    background-color: #999;
    bottom: 0;
    position: fixed;
    overflow: auto;
    right: 0;
    width: 250px;
    top: 0;
    z-index: 2;
}

.placement-advertisers.collapsed {
    background-color: #000;
    width: 20px;
}

.placement-advertisers-loading {
    padding: 10px;
}

.placement-rack-saving {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    color: #fff;
    border-radius: 20px;
    font-size: 1.6rem;
    position: absolute;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: visibility 5s;
}

.placement-rack-save-status {
    transition: background-color 250ms;
    padding: 2px;
    border-radius: 10px;
}
.placement-rack-save-status.saved {
    transition: background-color 3s;
}
.placement-rack-save-status.saving {
    background-color: #9f9;
}