.RacksPage {
    padding: 10px;
    width: 800px;
    margin: 0 auto;
}

.RacksPage-rack {
    width: 300px;
}

.RacksPage-distribution {
    flex-grow: 1;
}

.RacksPage-distribution h1 {
    margin: 0 0 30px;
}