.LoadingIndicator {
    display: inline-block;
    vertical-align: middle;
}
.LoadingIndicator-symbol {
    display: grid;
    grid-template-columns: 50% 50%;
    animation: linear LoadingIndicatorSpin 1s infinite;
}

.LoadingIndicator-symbol > div {
    background-color: #000;
    width: 6px;
    height: 6px;
}

.LoadingIndicator-symbol > div:nth-child(1),
.LoadingIndicator-symbol > div:nth-child(4) {
    background-color: #ff0;
}

@keyframes LoadingIndicatorSpin {
    100% {
        transform: rotate(360deg);
    }
}