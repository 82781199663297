.RackLoaderScreen {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.RackLoaderScreen-content {
    z-index: 3;
}