.Panel {
    /*border: 1px solid #ccc;*/
    /*border-top: none;*/
    /*border-left: none;*/
    /*border-right-color: #aaa;*/
    /*border-bottom-color: #e0e0e0;*/
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border-radius: 5px;
}
