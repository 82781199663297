.racks-PocketCell {
    background-color: #fff;
    /*border: 2px solid #6c98ba;*/
    /*border: 2px solid #000;*/
    /*box-shadow: 1px 1px 1px #999;*/
    /*border-radius: 4px;*/
    /*border: 1px solid #f0f0f0;*/
    /*border: 1px solid #ddd;*/
    border: 1px solid transparent;
    font-size: small;
    position: relative;
    user-select: none;
}

.racks-PocketCell.clickable {
    cursor: pointer;
}

.racks-PocketCell.clickable:hover {
    border-color: #9f0;
}

.racks-PocketCell.highlight {
    background: #afa !important;
    outline: 2px solid #afa;
    box-shadow: 0 0 10px #afa;
}
.racks-PocketCell.duplicate {
    /*box-shadow: 1px 1px 10px #c00;*/
    background-color: #fd0;
    /*border: 1px solid #c00;*/
}

.racks-PocketCell.hovering {
    background-color: #9fa;
    transition: background-color 250ms;
}
.racks-PocketCell.dropped {
    /*background-color: #3ca;*/
    transition: background-color 0.5s;
}

.racks-PocketCell.saving {
    background-color: #cfc;
    animation: linear SavingPocket 500ms infinite;
}

.racks-PocketCell.expired {
    /*background-color: #fcc;*/
    /*outline: 1px solid #c00;*/
}

.racks-PocketCell.error {
    background-color: #f00;
    color: #fff;
}

@keyframes SavingPocket {
    0% { background-color: #cfc; }
    50% { background-color: #afa; }
}

.racks-PocketCell-custom {
    background: #ff0;
    color: #666;
    font-size: 10px;
    font-style: italic;
    position: absolute;
    padding: 4px;
    top: 0;
    right: 0;
}

.racks-PocketCell-expired {
    position: absolute;
    border: 1px solid #c00;
    color: #c00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
    font-weight: bold;
    padding: 5px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.racks-PocketCell-expired-desc {
    /*display: none;*/
    transform: rotate(-45deg);
    opacity: 0.5;
}

.racks-PocketCell:hover .racks-PocketCell-expired {
    /*background-color: rgba(255, 0, 0, 0.5);*/
    /*color: #fff;*/
    /*justify-content: center;*/
    /*transform: rotate(-45deg);*/
}

.racks-PocketCell:hover .racks-PocketCell-expired-desc {
    opacity: 0.1;
}

.racks-PocketCell-droppable {
    min-height: 30px;
    padding: 3px;
}

.racks-PocketCell-options {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    z-index: 10;
}

.racks-PocketCell-options-info {
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px;
}

.racks-PocketCell .name {
    /*color: #000;*/
    /*font-weight: bold;*/
    /*font-size: 0.75rem;*/
}
.racks-PocketCell .contract {
    font-size: 0.75rem;
    color: #666;
}
.racks-PocketCell .dates {
    /*background-color: rgba(0, 0, 0, 0.5);*/
    /*color: #fff;*/
    color: #666;
    /*position: absolute;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*bottom: 0;*/
    /*padding: 5px;*/
    box-sizing: border-box;
    /*transform: translateY(-100%);*/
    /*border-top: 1px dotted #000;*/
    font-size: 0.75rem;
    font-style: italic;
}