.LoginPage {
    width: 100vw;
    height: 100vh;
}

/*.LoginPage-box {*/
/*    background-color: #fff;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0 0 100px rgba(255, 255, 130, 1);*/
/*    display: inline-block;*/
/*    padding: 30px;*/
/*}*/

/*.LoginPage-box h1 {*/
/*    color: #aa4;*/
/*    font-size: 18px;*/
/*    margin: 0;*/
/*}*/