.Tabs {
}
.Tabs-tabs {
    border-bottom: 1px solid #efefef;
    padding-left: 10px;
}

.Tabs-tab {
    display: none;
}

.Tabs-button {
    border-bottom: 3px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    padding: 10px 15px;
}
.Tabs-button:hover {
    color: #fc0;
}

.Tabs-button.active {
    /*background-color: #fc0;*/
    border-bottom-color: #fc0;
    color: #fc0;
    font-weight: bold;
    text-decoration: none;
}

.Tabs-tab.active {
    display: block;
}

.Tabs-button.disabled {
    color: #999;
    cursor: not-allowed;
    font-style: italic;
}