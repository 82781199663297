.Modal {
    background-color: #fff;
    border-radius: 10px;
    color: #000;
    margin: 20px auto;
    max-width: 100%;
    position: relative;
    width: 800px;
}

.Modal-header-buttons {
    position: absolute;
    right: 2px;
    top: 2px;
}

.Modal-header {
    border-bottom: 1px solid #eee;
}
.Modal-title {
    /*background-color: #321c94;*/
    background-color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: bold;
    color: #000;
    padding: 7px 5px 5px;
    text-align: center;
}