.Tag {
    background-color: #ccc;
    color: #333;
    border-radius: 5px;
    display: inline-block;
    font-size: .8rem;
    padding: 5px;
}

.clickable.Tag:hover {
    background-color: #ddd;
    cursor: pointer;
}