.PocketOption {
    background-color: #fff;
    color: #666;
    /*border: 1px solid #fff;*/
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    margin-right: 3px;
    padding: 5px;
    text-align: center;
    vertical-align: center;
}

.PocketOption:hover {
    background-color: #000;
    border-color: #999;
    color: #fff;
}
.PocketOption:last-child {
    margin-right: 0;
}