.Button {
    background: none;
    border: none;
    border-radius: 3px;
    color: #333;
    display: inline-block;
    cursor: pointer;
    outline: 0;
}

.Default.Button, .Danger.Button, .Primary.Button, .Secondary.Button {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    padding: 5px 10px;
}

.Default.Button:hover, .Danger.Button:hover, .Primary.Button:hover, .Secondary.Button:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
}

.Primary.Button {
    background-color: #fff;
}

.Secondary.Button {
    background-color: #61dafb;
}

.Danger.Button {
    background-color: #c33;
    color: #fff;
    box-shadow: #f00;
}

.Link.Button {
    padding: 0;
    text-decoration: underline;
}

.disabled.Button {
    cursor: not-allowed;
}