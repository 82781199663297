.racks-SectionTable {
    border-collapse: separate;
    border-spacing: 2px;
    /*border-collapse: collapse;*/
}

.racks-SectionTable th {
    background-color: #444;
    color: #ddd;
    /*border: 2px solid #000; !*#6c98ba;*!*/
    border-radius: 10px;
    font-style: italic;
    padding: 3px;
    font-size: small;
    font-weight: bold;
    text-align: center;
}
.racks-SectionTable thead th {
    /*border-left: 1px solid #aaa;*/
}
.racks-SectionTable tbody th {
    /*border-bottom: 1px solid #aaa;*/
}

.racks-Section {
    margin-bottom: 10px;
}

.racks-Section main {
    background-color: #e1e1e1;
}

.racks-Section header {
    /*background-color: #3ca;*/
    /*background-color: #fc0;*/
    background-color: #6c98ba;
    /*border: 1px solid #999;*/
    display: flex;
    align-items: center;
    align-content: center;
    /*border-radius: 5px;*/
    padding: 5px 10px;
    vertical-align: center;
}
.racks-Section-name {
    flex-grow: 1;
    /*background-color: #6c98ba;*/
    /*background-color: #3ca;*/
    color: #fff;
    font-weight: bold;
    font-size: medium;
    text-align: left;
}

.racks-Section-actions {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: right;}

.racks-SectionRow-label {
    /*background-color: #6c98ba;*/
    background-color: #e1e1e1;
    border-top: 1px solid #aaa;
    /*border-radius: 10px;*/
    font-weight: bold;
    color: #444;
    padding: 5px;
    font-size: small;
    text-align: center;
}