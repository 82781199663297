.EditRackSections-table {}
.EditRackSections-table tr.deleting {
    background-color: #fcc;
}

.EditRackSections-table.editing tr {
    opacity: 0.2;
}

.EditRackSections-table.editing tr.active {
    opacity: 1;
}