.advertiser-list-Brochure {
    align-items: center;
    background-color: #fff;
    /*box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.25);*/
    color: #444;
    cursor: move;
    display: flex;
    gap: 5px;
    margin: 5px 0;
    padding: 5px 10px;
    position: relative;
}

.advertiser-list-Brochure.pending {
    background-color: #ffc;
}

.highlight.advertiser-list-Brochure {
    background-color: #afa;
}

.advertiser-list-Brochure .name {
    font-weight: bold;
    font-size: 0.9rem;
    pointer-events: none;
}

.advertiser-list-Brochure .company {
    pointer-events: none;
    font-size: 0.8rem;
}

.advertiser-list-Brochure .contract {
    font-size: 0.5rem;
    pointer-events: none;
}

.advertiser-list-Brochure .dates {
    pointer-events: none;
    font-size: 0.6rem;
}

.advertiser-list-Brochure .status {
    background: #ff0;
    color: #666;
    font-size: 10px;
    font-style: italic;
    position: absolute;
    padding: 4px;
    top: 0;
    right: 0;
}