.AdvertiserList {}
.AdvertiserList header {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 16px 12px;
    text-align: center;
}
.AdvertiserList main {
    padding: 5px;
}

.AdvertiserList-search {
    background-color: #3ca;
    font-size: 0.8rem;
    padding: 0;
    text-align: center;
}

.AdvertiserList-search__section {
    border-bottom: 1px solid #0a9;
    padding: 6px 0;
}

.AdvertiserList-search__section:last-child {
    border-bottom: none;
}