body {
  /*background-color: #ddc;*/
  /*background-color: #fff;*/
  background-color: #bba;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex.h-center {
  justify-content: center;
}

.flex.v-center {
  align-content: center;
  align-items: center;
}

.table {
  border-collapse: collapse;
  width: 100%;
}
.table thead th {
  text-align: left;
}

.table th, .table td {
  padding: 5px 10px;
}

.table tr {
  border-bottom: 1px solid #efefef;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}