.CodeBoxes, .CodeBoxes-label, .CodeBoxes-value {
    display: inline-block;
}

.CodeBoxes {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 0 2px;
}
.CodeBoxes-label, .CodeBoxes-value {
    padding: 5px;
}

.CodeBoxes-label.invalid, .CodeBoxes-value.invalid {
    color: #c00;
}

.CodeBoxes-label {
    color: #666;
    font-size: 0.7rem;
}
.CodeBoxes-value {
    border-right: 1px solid #e1e1e1;
    padding-left: 0;
    font-size: 1rem;
}

.CodeBoxes-value:last-child {
    border-right: none;
}