.Menu {
    position: relative;
    margin-right: 20px;
    padding: 5px;
}

.Menu:hover {
    background-color: #ccc;
}

.Menu-label {
    cursor: pointer;
}

.Menu-options {
    background-color: #fff;
    padding: 5px 0;
    position: absolute;
    left: 0;
    z-index: 100;
}