.StatusIndicator {
    border: 1px dashed #444;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 12px;
    vertical-align: middle;
    width: 12px;
    animation: statusIndicatorInitializing 4s linear infinite;
}

@keyframes statusIndicatorInitializing {
    100% {
        transform: rotate(360deg);
    }
}

.Done.StatusIndicator {
    border-color: #060;
    border-style: solid;
    background-color: #0c0;
}

.Loading.StatusIndicator {
    background-color: #0c0;
    animation: statusIndicatorLoading 1s infinite;
}

.Failed.StatusIndicator {
    border-style: solid;
    border-color: #800;
    background-color: #c00;
}

@keyframes statusIndicatorLoading {
    0% { background-color: #9c0; }
    50% { background-color: #9f0; }
}